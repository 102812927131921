<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin phiếu yêu cầu duyệt chi'">
      <template v-slot:preview>
        <b-card no-body class="full-width">
          <div>
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div class="wizard-nav" style="background-color: #eef0f8">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Thông tin</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                    v-show="mainModel.id"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Tệp đính kèm</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                    v-show="mainModel.id"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Lịch sử</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom card-shadowless rounded-top-0">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-xl-12">
                      <div
                        class="py-8 px-4 py-lg-15 px-lg-4 pb-5 full-width"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                        <ApprovalCommonData
                          :mainModel="mainModel"
                          v-if="renderComponent"
                        >
                        </ApprovalCommonData>
                        <b-button
                          style="
                            fontweight: 600;
                            width: 70px;
                            margin-right: 10px;
                          "
                          variant="primary"
                          size="sm"
                          @click="onSubmit"
                          v-if="isShowSubmit()"
                          >Lưu</b-button
                        >
                        <b-button
                          style="font-weight: 600; width: 70px"
                          variant="secondary"
                          size="sm"
                          @click="onBack"
                          >Hủy</b-button
                        >
                      </div>
                    </div>
                    <div
                      class="py-4 mb-5 full-width"
                      data-wizard-type="step-content"
                    >
                      <b-container class="bv-example-row">
                        <UploadFile
                          v-if="renderComponent"
                          :id="mainModel.code"
                          :entity="'purchase-orders'"
                        />
                      </b-container>
                    </div>
                    <div
                      class="col-xl-12 pb-5 full-width"
                      data-wizard-type="step-content"
                    >
                      <div class="approval-history col-12 mt-4">
                        <ApprovalHistory
                          v-if="renderComponent"
                          :approvalId="mainModel.id"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import timeUtils from '@/utils/date';
import UploadFile from '@/view/modules/upload/Upload';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import { TIME_TRIGGER, APPROVAL_TYPE } from '@/utils/constants';
import decounce from 'debounce';
import ApiService from '@/core/services/api.service';
import ApprovalCommonData from '../../components/approval/ApprovalCommonData.vue';
import ApprovalHistory from '../../components/approval/ApprovalHistory.vue';
import {
  formatDate,
  makeToastFaile,
  makeToastSuccess,
  unMaskPrice,
  currencyMask,
} from '@/utils/common';
import { checkPermission } from '@/utils/saveDataToLocal';
import { APPROVAL_STEP_STATUS } from '@/utils/enum';

export default {
  data() {
    return {
      currency: currencyMask,
      mainModel: {
        id: null,
        code: '',
        paymentDate: new Date(),
        type: 1,
        amount: 0,
        vatAmount: 0,
        customerId: null,
        customerName: '',
        customerMobile: '',
        customerBankName: '',
        customerBankNo: '',
      },
      dpConfigs: timeUtils.DP_CONFIG,
      editable: true,
      listType: APPROVAL_TYPE,
      filteredOptionsCustomer: [],
      optionsCustomer: [],
      renderComponent: false,
    };
  },
  components: {
    KTCodePreview,
    UploadFile,
    ApprovalCommonData,
    ApprovalHistory,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu duyệt chi', route: '/approval' },
      { title: 'Thông tin phiếu yêu cầu duyệt chi' },
    ]);
    // Initialize form wizard
    const hashName = this.$route.hash ? this.$route.hash.split('#')[1] : '';

    this.hashName = hashName === 'attach' ? 'attach' : null;

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: hashName === 'attach' ? 3 : 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
    if (this.$route.query.id) {
      this.mainModel.id = this.$route.query.id;
      this.getApprovalInfoById();
    } else {
      this.renderComponent = true;
    }
  },
  methods: {
    checkPermission,
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    onSelectCustomer(option) {
      if (option.item.id) {
        this.mainModel.customerId = option.item.id;
        this.mainModel.customerMobile = option.item.phoneNo;
        this.mainModel.customerName = option.item.fullName;
      } else {
        this.mainModel.customerId = null;
        this.mainModel.customerMobile = '';
        this.mainModel.customerName = '';
      }
    },
    onSearchChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.mainModel.customerMobile = searchQuery;
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer];
      this.debounceInput();
    },
    getListCustomer() {
      const text = this.mainModel.customerMobile
        ? this.mainModel.customerMobile.trim()
        : null;
      if (!text || text.length < 5) {
        return;
      }
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          this.optionsCustomer = data.data.data;
          if (this.optionsCustomer.length > 0) {
            this.filteredOptionsCustomer = [...this.optionsCustomer];
          } else {
            this.filteredOptionsCustomer = [
              { message: 'SĐT không có trong hệ thống' },
            ];
          }
        },
      );
    },
    onSubmit() {
      const payload = {
        ...this.mainModel,
        amount: unMaskPrice(this.mainModel.amount),
        vatAmount: unMaskPrice(this.mainModel.vatAmount),
        paymentDate: formatDate(this.mainModel.paymentDate, 'yyyy-MM-DD'),
      };
      if (!payload.id) {
        this.onCallApiUpsert(payload, 'post');
        return;
      }
      this.onCallApiUpsert(payload, 'put');
    },
    onBack() {
      this.$router.push({
        name: 'list-approval',
      });
    },
    getApprovalInfoById() {
      ApiService.get(`v2/approvals/${this.mainModel.id}`)
        .then(({ data }) => {
          this.mainModel = data.data;
          this.renderComponent = true;
          this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Duyệt phiếu: <a class='text-primary'>#${this.mainModel.code}</a></span>
              </div>`;
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    onCallApiUpsert(payload, method) {
      if (this.onSubmiting) {
        return;
      }
      this.onSubmiting = true;
      ApiService[method]('v2/approvals', payload)
        .then(({ data }) => {
          if (data.status === 200) {
            makeToastSuccess(data.message);
            this.onBack();
          } else {
            makeToastFaile(data.message);
            this.onSubmiting = false;
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.onSubmiting = false;
        });
    },
    isShowSubmit() {
      if (this.mainModel.status !== APPROVAL_STEP_STATUS.NEW) {
        return false;
      }
      if (this.mainModel.id) {
        return this.checkPermission('APPROVAL_UPDATE');
      }
      return this.checkPermission('APPROVAL_INSERT');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
